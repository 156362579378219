import { useStore, useStoreHelpers } from 'src/composables/useStore'
import { useRouter } from 'src/composables/useRouter'
import { LoadManager } from 'src/utils/manager/LoadManager';
import {
  getTopLevelCategories,
  getRelatedCategories,
  getAndStoreRelatedProducts,
  getEsputnikUrl,
  getNonProductCategoryIds
} from 'theme/helpers';
import { computed, reactive, ref, watch } from 'vue';
// import { onlineHelper } from '@vue-storefront/core/helpers';
import { formatRegionUrl } from '$modules/shipping/utils/region-url';
import config from 'config';

interface ShippingState {
  shop?: {
    id: string | number
  },
  method?: string,
  npShop?: {
    ref: string
  },
  loaded?: boolean
}

export function useHome () {
  const store = useStore()
  const { useState, useGetter, useAction, useMutation } = useStoreHelpers()
  const loadManager = reactive(new LoadManager())
  const router = useRouter()

  const loadNewsletterPopup = ref(false)
  const selectedPopularCategoryIndex = ref(0)
  const nonProductCategoryIds = ref([])
  const esUrlParamProductPopularMainWidget = ref('')
  const esUrlParamCafeProductsMainWidget = ref('')
  const heroComponent = ref(null)
  const interestingCategoriesComponent = ref(null)

  // States
  const needToOpenAuth = useState<boolean>('ui', 'needToOpenAuth')
  const heroImages = useState<string[]>('promoted', 'headImage')
  const getBrandsList = useState<any[]>('brands', 'brandsList')
  const getCategories = useState<any[]>('category', 'list')
  const currentShipping = useState<ShippingState>('shipping-module', 'current')
  const deviceInfo = useState<any>('ui', 'deviceInfo')
  // Getters
  const isLoggedIn = useGetter<boolean>('user', 'isLoggedIn')
  const getSampleSpecialCategories = useGetter<any[]>('homepage', 'getSampleSpecialCategories')
  const getSampleProducts = useGetter<any[]>('homepage', 'getSampleProducts')
  const getSampleProductsPopular = useGetter<any[]>('homepage', 'getSampleProductsPopular')
  const getSampleProductsVarusCafe = useGetter<any[]>('homepage', 'getSampleProductsVarusCafe')
  const getProductRelated = useGetter<any[]>('product', 'getProductRelated')
  const mainCategory = useGetter<any>('homepage', 'getMainCategory')
  const getBordProducts = useGetter<any[]>('homepage', 'getBordProducts')
  const productsInWishlist = useGetter<any[]>('wishlist', 'getWishlistItems')

  // Actions
  const loadMainCategory = useAction('homepage', 'loadMainCategory')
  const updateCart = useAction('cart', 'updateCart')
  const openMicrocart = useAction('ui', 'toggleMicrocart')
  const loadBordProduct = useAction('homepage', 'loadBordProduct')
  // Mutations
  const updateFilters = useMutation('products', 'UPDATE_FILTERS')
  const loadSampleSpecialCategories = useAction('homepage', 'loadSampleSpecialCategories')

  // Computed
  const isHeroReady = computed(() => {
    const state = loadManager.getBlockState('hero')
    const hasData = Boolean(state?.data)
    const hasComponent = Boolean(state?.component)
    return hasData && hasComponent
  })
  const heroData = computed(() => {
    const state = loadManager.getBlockState('hero');
    return state ? state.data : null;
  })
  const interestingCategoriesReady = computed(() => {
    const state = loadManager.getBlockState('interesting-categories')
    const hasData = Boolean(state?.data)
    const hasComponent = Boolean(state?.component)
    return hasData && hasComponent
  })
  const interestingCategories = computed(() => {
    const state = loadManager.getBlockState('interesting-categories');
    return state ? state.data : null;
  })

  const shippingChangeTrigger = computed(() => {
    return {
      shopId: currentShipping.value?.shop?.id,
      method: currentShipping.value?.method,
      newPostRef: currentShipping.value?.npShop?.ref
    }
  })
  // const isOnline = computed(() => {
  //   return onlineHelper.isOnline;
  // })
  const heroes = computed(() => {
    return heroImages
  })
  const sampleSpecialCategories = computed(() => {
    return getSampleSpecialCategories
  })
  const sampleProducts = computed(() => {
    return getSampleProducts
  })
  const sampleProductsPopular = computed(() => {
    return getSampleProductsPopular
  })
  const getSeoHtml = computed(() => {
    return mainCategory.value.description
  })
  const isShowFavorites = computed(() => {
    return productsInWishlist.value.length && isLoggedIn
  })
  const productOffersMainWidgetId = computed(() => {
    return config.esputnik?.widgetIds?.productOffersMain;
  })
  const productPopularMainWidgetId = computed(() => {
    return config.esputnik?.widgetIds?.productPopularMain;
  })
  const cafeProductsMainWidgetId = computed(() => {
    return config.esputnik?.widgetIds?.cafeProductsMain;
  })
  const offersMainProducts = computed(() => {
    const products = getProductRelated.value['productOffersMain'] || []
    return products;
  })
  const popularMainProducts = computed(() => {
    const products = getProductRelated.value['productPopularMain'] || []
    return products.length ? products : sampleProductsPopular.value;
  })
  const cafeProductsMain = computed(() => {
    const products = getProductRelated.value['productCafeMain'] || []
    return products.length ? products : getSampleProductsVarusCafe.value;
  })
  const popularCategories = computed(() => {
    const topLevelCategories = getTopLevelCategories(getCategories.value);

    const isNotExcludedCategory = (category) => !nonProductCategoryIds.value.includes(category.id);
    const isProductCategory = (category) => category.is_not_product_category === false;
    const productCategories = topLevelCategories
      .filter(isNotExcludedCategory)
      .filter(isProductCategory);

    return getRelatedCategories(popularMainProducts.value, productCategories) || [];
  })
  const popularProducts = computed(() => {
    if (!popularCategories.value.length) {
      return []
    }
    const category = popularCategories.value[selectedPopularCategoryIndex.value]
    return category?.products || []
  })

  // Methods
  const getProductPopularMain = () => {
    getAndStoreRelatedProducts(
      productPopularMainWidgetId.value,
      'productPopularMain',
      config?.entities?.product?.queryMaxSize,
      true
    )
    esUrlParamProductPopularMainWidget.value = getEsputnikUrl('#' + productPopularMainWidgetId.value + ' .recommend-product-id')
  }
  const getCafeProductsMain = () => {
    getAndStoreRelatedProducts(
      cafeProductsMainWidgetId.value,
      'productCafeMain',
      config?.entities?.product?.queryMaxSize,
      true
    )
    esUrlParamCafeProductsMainWidget.value = getEsputnikUrl('#' + cafeProductsMainWidgetId.value + ' .recommend-product-id')
  }
  const setPopularCategory = (index) => {
    selectedPopularCategoryIndex.value = index
  }
  const getNonProductCategoryIdslocal = async () => {
    nonProductCategoryIds.value = await getNonProductCategoryIds()
  }

  const loadHomeInfoBlock = async () => {
    // const response = await loadCmsBlockByIdentifier({
    //   value: 'home-info-block'
    // })
    // console.log('response', response)
  }
  // Watch

  watch(isLoggedIn, () => {
    const redirectObj = localStorage.getItem('redirect');
    if (redirectObj) router.push(redirectObj);
    localStorage.removeItem('redirect');
  })

  watch(shippingChangeTrigger, async (newValue, oldValue) => {
    if (
      (!newValue?.shopId || !oldValue?.shopId) || (
        newValue?.shopId && (
          newValue?.shopId === oldValue?.shopId &&
          newValue?.method === oldValue?.method &&
          newValue?.newPostRef === oldValue?.newPostRef
        )
      )
    ) return

    await Promise.all([
      loadMainCategory(),
      loadBordProduct(),
      getProductPopularMain(),
      loadSampleSpecialCategories(),
      getCafeProductsMain()
      // loadSampleProductsPopular(true)
    ])
  })

  return {
    store,
    // States
    needToOpenAuth,
    heroImages,
    loadManager,
    getBrandsList,
    getCategories,
    currentShipping,
    deviceInfo,
    esUrlParamProductPopularMainWidget,
    esUrlParamCafeProductsMainWidget,
    selectedPopularCategoryIndex,
    // Getters
    isLoggedIn,
    getSampleSpecialCategories,
    getSampleProducts,
    getSampleProductsPopular,
    getSampleProductsVarusCafe,
    getProductRelated,
    mainCategory,
    getBordProducts,
    // Methods
    loadMainCategory,
    updateCart,
    updateFilters,
    getNonProductCategoryIdslocal,
    getProductPopularMain,
    getCafeProductsMain,
    formatRegionUrl,
    loadHomeInfoBlock,
    setPopularCategory,
    // Computed
    isHeroReady,
    heroData,
    interestingCategoriesReady,
    interestingCategories,
    isShowFavorites,
    popularMainProducts,
    popularProducts,
    sampleSpecialCategories,
    cafeProductsMain,
    productOffersMainWidgetId,
    productPopularMainWidgetId,
    cafeProductsMainWidgetId,
    offersMainProducts,
    popularCategories,
    loadNewsletterPopup,
    heroComponent,
    interestingCategoriesComponent,
    openMicrocart,
    shippingChangeTrigger,
    heroes,
    sampleProducts,
    sampleProductsPopular,
    getSeoHtml
  }
}

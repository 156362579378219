import { getCurrentInstance } from 'vue';

export function useRouter () {
  const instance = getCurrentInstance();
  return instance.proxy.$router;
}
export function useRoute () {
  const instance = getCurrentInstance();
  return instance.proxy.$route;
}

import Vue, { ref, computed } from 'vue'
import { Priority } from 'src/utils/scheduler/types'
import { useStore } from './useStore'
import { LoadStatus } from 'src/utils/manager/LoadManager'
export function useBlockLoader (loadManager: any) {
  const store = useStore()
  const loadedComponents = ref({})
  // Додаємо лічильник оновлень
  const updateCounter = ref(0)

  // Methods
  const initPageBlock = (blockConfig: any) => {
    loadManager.loadBlock({
      id: blockConfig.id,
      priority: blockConfig.priority,
      loadData: () => blockConfig.loadData(store),
      loadComponent: blockConfig.loadComponent
    })
  }

  const initBlocks = (blocks: any[]) => {
    // Спочатку створюємо всі стани
    blocks.forEach(block => {
      const state = Vue.observable({
        status: LoadStatus.LOADING,
        ready: false,
        component: null,
        data: null,
        error: null,
        isEmpty: false
      });
      loadManager.blockStates.set(block.id, state);
    });

    // Потім запускаємо завантаження
    blocks.forEach(block => {
      loadManager.loadBlock({
        id: block.id,
        priority: block.priority,
        loadData: () => block.loadData(store),
        loadComponent: block.loadComponent
      })
    });
  }

  const initSingleBlock = (block: any) => {
    // Створюємо початковий стан для блоку
    const state = Vue.observable({
      status: LoadStatus.LOADING,
      ready: false,
      component: null,
      data: null,
      error: null,
      isEmpty: false
    });
    loadManager.blockStates.set(block.id, state);

    // Запускаємо завантаження
    loadManager.loadBlock({
      id: block.id,
      priority: block.priority,
      loadData: () => block.loadData(store),
      loadComponent: block.loadComponent
    });
  }

  const initCriticalBlocks = (blocks: any[]) => {
    blocks
      .filter(block => block.priority === Priority.CRITICAL)
      .forEach(block => initPageBlock(block))
  }

  const setupLazyBlocks = (blocks: any[], refs: any) => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const blockId = (entry.target as HTMLElement).dataset.blockId
          const block = blocks.find(b => b.id === blockId)

          if (block) {
            console.log(`[LazyBlocks] Block ${blockId} is intersecting, initializing...`)
            initPageBlock(block)
          }

          observer.unobserve(entry.target)
        }
      })
    })

    // Спостерігаємо за всіма блоками
    blocks.forEach(block => {
      const el = refs[block.id]
      if (el) {
        console.log(`[LazyBlocks] Setting up observer for ${block.id}`)
        el.dataset.blockId = block.id
        observer.observe(el)
      }
    })

    // Очищення observer при розмонтуванні компонента
    // onUnmounted(() => {
    //   observer.disconnect()
    // })
  }

  // Computed
  const getBlockState = (blockId: string) => {
    // Використовуємо updateCounter для тригеру реактивності
    return computed(() => {
      // eslint-disable-next-line no-unused-expressions
      updateCounter.value; // просто читаємо для реактивності
      return loadManager.blockStates.get(blockId);
    })
  }

  const isBlockReady = computed(() => {
    return (blockId: string) => {
      const state = getBlockState(blockId).value
      // console.log(`[BlockReady] Check for ${blockId}:`, {
      //   state,
      //   stateExists: !!state,
      //   readyFlag: state?.ready,
      //   componentExists: !!state?.component,
      //   dataExists: !!state?.data,
      //   mapSize: loadManager.blockStates.size
      // });

      const hasData = Boolean(state?.data);
      const hasComponent = Boolean(state?.component);
      const result = hasComponent && hasData;

      return result;
    }
  })

  const getBlockData = computed(() => {
    return (blockId: string) => {
      const state = getBlockState(blockId).value
      return state ? state.data : null
    }
  })

  const getBlockComponent = computed(() => {
    return (blockId: string) => {
      const state = getBlockState(blockId).value
      return state ? state.component : null
    }
  })

  // Модифікуємо loadBlock в LoadManager щоб він тригерив оновлення
  const originalLoadBlock = loadManager.loadBlock;
  loadManager.loadBlock = async (config: any) => {
    const result = await originalLoadBlock.call(loadManager, config);
    // Інкрементуємо лічильник після кожного оновлення
    updateCounter.value++;
    return result;
  };

  const isBlockEmpty = (id: string): boolean => {
    const state = loadManager.blockStates.get(id);
    return state?.isEmpty || false;
  }

  const hasBlockError = (id: string): boolean => {
    const state = loadManager.blockStates.get(id);
    return state?.status === LoadStatus.ERROR;
  }

  const getBlockError = (id: string): Error | undefined => {
    return loadManager.blockStates.get(id)?.error;
  }

  return {
    // State
    loadedComponents,

    // Methods
    initPageBlock,
    initBlocks,
    initSingleBlock,
    initCriticalBlocks,
    setupLazyBlocks,

    // Computed
    getBlockState,
    isBlockReady,
    getBlockData,
    getBlockComponent,
    isBlockEmpty,
    hasBlockError,
    getBlockError
  }
}
